@use "sass:meta" as ---cbgyy2mpyak;/*
 * Copyright Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$spinner-color: $go-primary;
$spinner-border-color: #f3f3f3;

.page-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border: 16px solid $spinner-border-color;
  border-top: 16px solid $spinner-color;
  animation: spin 2s linear infinite;
}

.small {
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border-width: 8px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

;@include ---cbgyy2mpyak.load-css("sass-embedded-legacy-load-done:2574");